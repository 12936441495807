import React, { Fragment } from "react";
import "animate.css";
import { CHECKMARK, MODELS1 } from "../images";
import { NavLink } from "react-router-dom";
import AboutModel from "../about-model";
import { ASSETS_URL } from "../../config/api-urls";
import moment from "moment";
import { LuMapPin } from "react-icons/lu";
import { IoCalendarNumberOutline } from "react-icons/io5";
import { MdVerified } from "react-icons/md";
import { AnnouncementBadge } from "./AnnouncementBadge";
import ModelProfileCard from "./ModelProfileCard";
import ModelProfileCardSideView from "./ModelProfileCardSideView";



const AnnouncementItem = (props) => {
  // const modelData = props.model
  const { model, announcement, type = "GOLD" } = props;

  if(!model) return null;


  return (
    <NavLink to={`/announcement/${announcement?._id}`}>
      <div className="position-relative overflow-hidden z-1 cursor-pointer media-container ">
        <div className="item-media animate__backInUp ">
          
          <div className="d-flex">
            <AnnouncementBadge  type={type} showVerified={model.isVerified} />
            </div>

          <img src={ASSETS_URL(announcement?.profilePhoto, model.gender )} style={{ height: "100%", objectFit : 'cover'}} />
          <div className="media-links"></div>


          <div className="annoucement-card-title">
              <div className="annoucement-card-title-city justify-content-between d-flex">
                <h5 className="font-17 bold ellipsis">{announcement.title}</h5>
                <span className="ms-4"> {announcement.user?.age} Years </span>
              </div>
                <div>
                  <span className="city-name">{announcement.contactDetails?.city} </span>
                </div>
            </div>

          {/*  */}

          {props.children}
        </div>

        <ModelProfileCardSideView model={model} />

        </div>
            
        
    </NavLink>
  );
};

export default AnnouncementItem;
