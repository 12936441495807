import React, { Fragment, useState } from "react";
import { WORDPRESSLOGO } from "../../componet/images";
import "./register.css";
import "../Login/Login.css";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";
import InputForm from "../../componet/InputForm";
import { register } from "../../store/register/register.action";
import Buttons from "../../componet/Buttons";
import { Formik } from "formik";
import { toast } from "react-toastify";
import Loader from "../../componet/theme/Loader";
import { LineWave } from "react-loader-spinner";

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import moment from 'moment'


const Register = (props) => {
  const navigate = useNavigate();

  const today = moment();
  const maxDate = new Date(today.subtract(18, "years")); // Calculate the date 18 years ago


  const [isLoader, setIsLoader] = useState(false);
  const [startDate, setStartDate] = useState(maxDate);
  const [age, setAge] = useState(0);
  const [isTAndCAccepted, setIsTAndCAccepted] = useState(false);


  const handleRegisterData = async (event) => {
    event.preventDefault();


    if(age < 18){
      toast.error('Age must be above 18+');
      return false;
    }

    if(!isTAndCAccepted){
      toast.error('Please accept Terms & Conditions');
      return false;
    }

    var data = new FormData(event.target);

    data.append('age',age)
    data.append('DOB',moment(startDate).format('DD-MM-YYYY'));

    try {
      setIsLoader(true);
      const response = await register(data);
      if (response) {
        navigate("/home");
        toast.success("Register Successfully");
        props.closeRegister();
      } else {
        alert(response.data);
      }
    } catch (error) {
      toast.error(error);
    }
    setIsLoader(false);
  };

  const calculateAge = (selectedDate) => {
    const today = moment();
    const birthDate = moment(selectedDate);

    const years = today.diff(birthDate, "years");
    return years;
  };

  const handleDateChange = (e) => {

    const date = e.target.value;
    console.log('date',date)

    setStartDate(date);
    // Example: Calculate and log age when the date changes
    const age = calculateAge(date);
    console.log('age',age)
    setAge(age)
  };

  const handleOnChangeTAndC = () => {
    setIsTAndCAccepted(!isTAndCAccepted)
  }


  // if (isLoader) {
  //   return <Loader />;
  // }

  return (
    <Fragment>
      <div className="bg-register">
        <Formik
          initialValues={{ email: "", password: "", TandC : false }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "Required";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            console.log("values", values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleRegisterData}>
              <InputForm
                titel="name"
                inputType="text"
                placeHolder="Enter your name"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                required={true}
              />
              {errors.name && touched.name && errors.name}

              <InputForm
                titel="email"
                inputType="email"
                placeHolder="Enter your email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                required={true}
              />

              {errors.email && touched.email && errors.email}
            
             

              {/* <InputForm
                titel="phone"
                inputType="number"
                placeHolder="phone number"
                name="phoneNumber"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phoneNumber}
              />
              {errors.phoneNumber && touched.phoneNumber && errors.phoneNumber} */}

              <div className="mt-3">
                <p className="input-title">gender</p>

                <select
                  name="gender"
                  id="gender"
                  className="form-input input-title w-100"
                  style={{ borderRadius: "none", color: "white" }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.gender}
                  required={true}
                >
                  <option value="">-Select Gender-</option>
                  <option value="Male" style={{ color: "black" }}>
                    Male
                  </option>
                  <option value="Female" style={{ color: "black" }}>
                    Female
                  </option>
                  <option value="Other" style={{ color: "black" }}>
                    Other
                  </option>
                </select>

                {errors.gender && touched.gender && errors.gender}
              </div>

              <div className="mt-3">
                <p className="input-title">DOB</p>

                <input
                  type="date" 
                  defaultValue={startDate} 
                  max={moment(maxDate).format('YYYY-MM-DD')}
                  className="form-input input-title  w-100"
                  wi
                  style={{ borderRadius: "none", color: "white", width : '100%' }}
                  onChange={(date) => handleDateChange(date)} />  
                  {/* <div style={{ color: "red" }}>Only Allow 18+</div> */}

                {/* <select
                  name="accountType"
                  id="accountType"
                  className="form-input input-title  w-100"
                  style={{ borderRadius: "none", color: "white" }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.accountType}
                >
                  <option value="">-Select Type-</option>
                  <option value="Private" style={{ color: "black" }}>
                    Private
                  </option>
                  <option value="Agency" style={{ color: "black" }}>
                    Agency
                  </option>
                  <option value="Visitor" style={{ color: "black" }}>
                    Libertine or visitor
                  </option>
                </select>

                {errors.accountType && touched.accountType && errors.accountType} */}
              </div>

              <InputForm
                titel="password"
                inputType="password"
                placeHolder="enter your password"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                required={true}
              />
              {errors.password && touched.password && errors.password}

             <div className="mt-4">
              <input
                  type="checkbox"
                  placeHolder=""
                  onChange={handleOnChangeTAndC}
                  checked={isTAndCAccepted}
                />

                <span> Accept Terms & Conditions </span>
             </div>

              <div className="mt-4">
                <Buttons
                  disableBtn={isLoader ? true : false}
                  className="w-100"
                  btnName={
                    isLoader ? (
                      <div className="loader-set">
                        <LineWave
                          visible={true}
                          height="100"
                          width="100"
                          color="black"
                          ariaLabel="line-wave-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          firstLineColor=""
                          middleLineColor=""
                          lastLineColor=""
                        />
                      </div>
                    ) : (
                      "Register"
                    )
                  }
                  type="submit"
                  disabled={isSubmitting}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Fragment>
  );
};

export default Register;
