import React from "react";

const Buttons = (props) => {
  return (
    <div>
      {
        props.disableBtn ? <button disabled className="btn-schedule in-btn-txt" type={props.type} >
          {props.btnName}
        </button> : <button {...props} className="btn-schedule in-btn-txt" type={props.type}>
          {props.btnName}
        </button>
      }
    </div>
  );
};

export default Buttons;
