import React, { useEffect } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import AnnouncementItem from './AnnouncementItem';
import { useSelector } from 'react-redux';
import { getPremiumAnnouncementsFromAPI } from '../../store/Models/Models.action';
import { useDispatch } from 'react-redux';
import { isMobileDevice } from '../../config/api-urls';
import ExpandedAnnouncement from './ExpandedAnnouncement';

const PremiumAnnouncementSwiper = (props) => {

  const dispatch = useDispatch()

  const {skip = 0} = props;
  const _announcementsPremium = useSelector((state) => state.modelsReducer.announcementsPremium);

  const loadPremiumAdds = () => {
    const data = {
      skip : skip,
      limit : 8,
      addPlacement : 'PREMIUM'
    }
    dispatch(getPremiumAnnouncementsFromAPI(data))
  }

  useEffect(() => {
    loadPremiumAdds()


  }, [skip]);

  if(_announcementsPremium.length == 0) return null

  
  return (
    <Swiper 
      spaceBetween={isMobileDevice() ? 0 : 50}
      slidesPerView={isMobileDevice() ? 1 : 2}  
      navigation={true} 
      modules={[Navigation]} className="mySwiper-preminum">
        {_announcementsPremium?.map((announcement, index) => (
          <SwiperSlide key={index}>
            {/* <AnnouncementItem type="Premium" model={announcement.user} announcement={announcement} /> */}
            <ExpandedAnnouncement type={"Premium"}  announcement={announcement} />
          </SwiperSlide>
        ))}
      </Swiper>
  )
}

export default PremiumAnnouncementSwiper