import React from 'react'
import { FEMALE_PLACEHOLDER } from '../../componet/images'
import { ASSETS_URL } from '../../config/api-urls'

const ModelPlaceholderImage = ({model}) => {
  return (
    <div className='text-center'>
        <img className='model-detail-placeholder-image rounded-2' src={ ASSETS_URL(model.profile,model.gender) } />
    </div>
  )
}

export default ModelPlaceholderImage