import PostRequest from "../../services/PostRequest";

export const getModel = (data = {}, isLoadMore = false) => {
  // console.log("call");
  // API CALL
  return async (dispatch) => {
    try {
      var response = await PostRequest("/web/users", data);

      if (isLoadMore) {
        dispatch({
          type: "GET_MORE_MODELS",
          value: response,
        });
      } else {
        dispatch({
          type: "GET_MODELS",
          value: response,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
};

export const getAnnouncementsFromAPI = (data = {}, isLoadMore = false) => {
  // console.log("call");
  // API CALL
  return async (dispatch) => {
    try {
      
      var response = await PostRequest("/web/announcements", data);
      dispatch({
        type: isLoadMore ? "LOAD_MORE_ANNOUNCEMENT"  : "GET_ANNOUNCEMENT",
        value: response,
      });

    } catch (error) {
      console.log("error", error);
    }
  };
};


export const getPremiumAnnouncementsFromAPI = (data = {}, isLoadMore = false) => {

  return async (dispatch) => {
    try {
      
      var response = await PostRequest("/web/announcements", data);
      dispatch({
        type: isLoadMore ? "LOAD_MORE_ANNOUNCEMENT_PREMIUM"  : "GET_ANNOUNCEMENT_PREMIUM",
        value: response,
      });

    } catch (error) {
      console.log("error", error);
    }
  };
};