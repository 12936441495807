import React from "react";
import Default from "../../componet/theme/Default";
import ModelHero from "../../componet/ModelHero";
import ItemContent from "../../componet/ItemContent";
import ContactUs from "../../componet/ContactUs";

const Contacts = () => {
  return (
    <Default>
      <ModelHero title="Contacts" />
      {/* <div className="contactMt"><ItemContent marginTop='9.3%' /></div> */}
      <ContactUs margintop='-175px' />
    </Default>

  );
};

export default Contacts;
