import React, { useState, useEffect, useRef } from 'react';

const MaxLinesText = ({ text, maxLines = false }) => {
  const [truncatedText, setTruncatedText] = useState('');
  const containerRef = useRef(null);

  useEffect(() => {

    console.log('maxLines',maxLines)

    
    const calculateTruncatedText = () => {
      const container = containerRef.current;
      const lineHeight = parseInt(getComputedStyle(container).lineHeight, 10);
      const maxPixelHeight = maxLines * lineHeight;

      if (container.scrollHeight > maxPixelHeight) {
        let truncatedText = text;
        while (container.scrollHeight > maxPixelHeight && truncatedText.length > 0) {
          truncatedText = truncatedText.slice(0, -1);
          container.textContent = truncatedText + '...';
        }
        setTruncatedText(truncatedText);
      } else {
        setTruncatedText(text);
      }
    };

    if(!maxLines){
        setTruncatedText(text);
        return false
    } else {
        calculateTruncatedText();
    }

    window.addEventListener('resize', calculateTruncatedText);
    return () => {
      window.removeEventListener('resize', calculateTruncatedText);
    };
  }, [text, maxLines]);

  return (
    <div ref={containerRef} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
      {truncatedText}
    </div>
  );
};

export default MaxLinesText;
