import React, { Fragment } from "react";
import { FaUser } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { toast } from "react-toastify";
import FormControl from "./FormControl";
import Buttons from "./Buttons";
import PostRequest from "../services/PostRequest";

const ContactUs = (props) => {
  const contactFormData = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    var object = {};
    formData.forEach(function (value, key) {
      object[key] = value;
    });
    console.log("contactForm", object);

    try {
      const response = await PostRequest("/web/contact-us", object);

      if (response) {
        toast.success(response);
        event.target.reset();
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <Fragment>
      <div className="contact-bg" style={{ marginTop: `${props.margintop}` }}>
        <div className="container">
          <div className="givePadding"></div>

          <div className="container">
            <div className="row mt-5">
              <div className="col-md-4 modelDetailsWidth">
                <h3 className="contactName">MyBella</h3>

                <h2 className="headindContact">Contact Us</h2>

                <div className="d-flex pt-5 gap-5">
                  <div className="title">
                    <p>phone</p>
                    <p className="pt-5">email</p>
                    <p className="mt-2">address</p>
                  </div>
                  <div className="description">
                    <span>(800) 123-45-67</span>
                    <p> (800) 123-45-68</p>

                    <p className="pt-3">mybella@example.com</p>

                    <span>316 Tipple Road</span>
                    <p>Philadelphia, PA 19143</p>
                  </div>
                </div>
              </div>

              <div className="col-md-8 modelDetailsWidth">
                <form onSubmit={contactFormData}>
                  <div className="d-flex gap-3 sm-display-block">
                    <FormControl
                      name="name"
                      types="text"
                      formIcon={<FaUser />}
                      inputMessage="user name"
                    />

                    <FormControl
                      name="email"
                      types="email"
                      formIcon={<MdEmail />}
                      inputMessage="Email Address"
                    />
                  </div>

                  <div className="d-flex gap-3 mt-3 sm-display-block">
                    <FormControl
                      name="phoneNumber"
                      types="text"
                      formIcon={<FaPhone />}
                      inputMessage="Phone Number"
                    />

                    <FormControl
                      name="city"
                      types="text"
                      formIcon={<FaLocationDot />}
                      inputMessage="Your City"
                    />
                  </div>

                  <textarea
                    name="message"
                    // style={{ width: "85%", height: "200px" }}
                    className="message-box mt-3 p-3 form-input tabInput"
                    placeholder="your message"
                  />

                  <div className="text-center mt-5 rightSide-setBtn tab-send-btn">
                    <Buttons type="submit" btnName="Contact Now  " />
                  </div>
                </form>
              </div>
            </div>
          </div>

          {props.children}
        </div>
      </div>
    </Fragment>
  );
};

export default ContactUs;
