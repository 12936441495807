import React, { Fragment } from "react";

const FormControl = (props) => {
  return (
    <Fragment>
      <div
        className="input-group mb-3 tabInput input-set inputInPhone"
        style={{
          border: "none",
          width: `${props.width ? props.width : "350px"}`,
        }}
      >
        <span className="input-group-text form-icons " id="basic-addon1">
          {props.formIcon}
        </span>

        <input
          type={props.types}
          name={props.name}
          className="form-input form-control "
          placeholder={props.inputMessage}
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </div>
    </Fragment>
  );
};

export default FormControl;
