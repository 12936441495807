import React from "react";
import { RiWhatsappFill } from "react-icons/ri";

const WhatsAppButton = (props) => {
  return (
    <button className="btn btn-success whatsapp-button" type="button"> <RiWhatsappFill className="" /> WhatsApp </button>
  );
};

export default WhatsAppButton;
