import React from "react";

import ImageGallery from "react-image-gallery";
import { MODEL1, MODEL2, MODEL4 } from "../../images";
import { ASSETS_URL } from "../../../config/api-urls";



const ModelGalary = (props) => {

  const { photos = [] } = props;
  
  const images = photos.map( image => {
   return {
      original: ASSETS_URL(image),
      thumbnail: ASSETS_URL(image),
      originalWidth :'auto',
      originalHeight :430,
      thumbnailHeight :80,
      thumbnailWidth :'auto',
   }
  })
  

  return (
    <div style={{ backgroundColor: "black" }}>
      <ImageGallery 
        showPlayButton={false} 
        showNav={false} 
        sizes={100}
        items={images} />

      {/* <div>
        <p className="preW-nextTaxt">{"< PREW / NEXT >"}</p>
      </div> */}
    </div>
  );
};

export default ModelGalary;
