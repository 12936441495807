import React from "react";
import { NavLink } from "react-router-dom";

const ModelHero = (props) => {
  return (
    <div className="modelBg">
      <div className="overlay">
        <div>
          <p className="menuItem modelHeading ">{props.title}</p>
          {/* <p className="homeText">
            <NavLink to={"/home"} style={{ color: "white" }}>
              HOME /
            </NavLink>
            <span className="ourModelsText"> {props.title}</span>
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default ModelHero;
