import React from "react";
import { AnnouncementBadge } from "./AnnouncementBadge";
import { NavLink } from "react-router-dom";
import {
  ASSETS_URL,
  formatPhoneNumberForWhatsApp,
  isMobileDevice,
} from "../../config/api-urls";
import Buttons from "../Buttons";
import WhatsAppButton from "../WhatsAppButton";
import MaxLinesText  from '../MaxLinesText'

const ExpandedAnnouncement = (props) => {
  const { announcement = null, type="V.I.P", showWebUrl = false } = props;

  if (!announcement) return null;


  if(isMobileDevice()){
    return(
      <div className="card bg-dark" style={{ width: "96%" }}>
     <NavLink to={`/announcement/${announcement._id}`}>
          {/* <AnnouncementBadge type={type}  showVerified={announcement.user?.isVerified} /> */}
          <img
            className="announcementImg"
            src={ASSETS_URL(announcement.profilePhoto)}
            alt=""
          />
        </NavLink>
      <div className="card-body">
        <NavLink to={`/announcement/${announcement._id}`}>
          <h2 className="card-title big">{announcement.title}</h2>
        </NavLink>
        <p className="contain-model pt-2">
          <MaxLinesText maxLines={5} text={announcement.description} />
        </p>

        <div className="d-flex">
            {announcement.contactDetails.contactPhoneNumber !== "" && (
              <div className="mt-2 btn-manage me-2">
                <a
                  target="__blank"
                  href={`https://wa.me/${formatPhoneNumberForWhatsApp(
                    announcement.contactDetails.contactPhoneNumber
                  )}?text=Bonjours%2C%20je%20vous%20contact%20de%20mybella.be%20et%20j%E2%80%99aimerais%20avoir%20plus%20d%E2%80%99informations`}
                >
                  <WhatsAppButton />
                </a>
              </div>
            )}

            {showWebUrl && announcement.contactDetails.website !== "" && (
              <div className="mt-2 btn-manage me-2">
                <a target="__blank" href={announcement.contactDetails.website}>
                  <Buttons btnName="Visit Website" style={{ width : 180 }} />
                </a>
              </div>
            )}
          </div>
      </div>
    </div>

    )
  }

  return (
    <div className="mt-5 d-flex expanded-announcement-wrapper">
      <div className="expanded-announcement-wrapper-photo me-5 relative">
        <NavLink to={`/announcement/${announcement._id}`}>
          {/* <AnnouncementBadge type={type}  showVerified={announcement.user?.isVerified} /> */}
          <img
            className="announcementImg"
            src={ASSETS_URL(announcement.profilePhoto)}
            alt=""
          />
        </NavLink>
      </div>

      <div className="expanded-announcement-wrapper-content">
        <div className="smallSize">
          <NavLink to={`/announcement/${announcement._id}`}>
            <h2 className="big ellipsis">{announcement.title}</h2>
          </NavLink>
          <p className="contain-model pt-2">{announcement.description}</p>

          <div className="d-flex pt-3">
            <div className="d-flex flex-column">
              <span className="model-name1">Contact</span>
              <p className="post1">
                {announcement.contactDetails.contactPhoneNumber}
              </p>
            </div>

          </div>

          <div className="d-flex">
            {announcement.contactDetails.contactPhoneNumber !== "" && (
              <div className="mt-2 btn-manage me-2">
                <a
                  target="__blank"
                  href={`https://wa.me/${formatPhoneNumberForWhatsApp(
                    announcement.contactDetails.contactPhoneNumber
                  )}?text=Bonjours%2C%20je%20vous%20contact%20de%20mybella.be%20et%20j%E2%80%99aimerais%20avoir%20plus%20d%E2%80%99informations`}
                >
                  <WhatsAppButton />
                </a>
              </div>
            )}

            {showWebUrl && announcement.contactDetails.website !== "" && (
              <div className="mt-2 btn-manage me-2">
                <a target="__blank" href={announcement.contactDetails.website}>
                  <Buttons btnName="Visit Website" />
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpandedAnnouncement;
