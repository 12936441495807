import React from "react";
import AboutModel from "../about-model";

const ModelProfileCardSideView = (props) => {

  const {model} = props

  return (
    <div className="item-content item-content-side-card">
    <div className="model-parameters-sideView">

         <div style={{ height : '100%' }} className="d-flex flex-column align-items-center justify-content-evenly text-center">
          <h5> {model?.name} </h5>

            <div>
                <h6> Gender </h6>
                <h6> Female </h6>
            </div>

            <div>
                <h6> Age </h6>
                <h6> {model?.age} Years </h6>
            </div>

            <div>
                <h6> Nationality </h6>
                <h6> {model?.nationality} </h6>
            </div>
         </div>


    </div>
    </div>
  );
};

export default ModelProfileCardSideView;
