import React, { Fragment, useEffect, useState } from "react";
import Default from "../../componet/theme/Default";
import Model from "../../componet/Model";
import ModelHero from "../../componet/ModelHero";
import "./ModelDetails.css";
import {
  CHECKMARK,
  MAP1,
  MODEL12,
  MODEL14,
  MODEL3,
  MODEL7,
  MODELS1,
} from "../../componet/images";
import SocialMedia from "../../componet/SocialMedia";
import { FaWhatsapp } from "react-icons/fa6";

import { FaXTwitter } from "react-icons/fa6";

import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import { FaRegStar } from "react-icons/fa";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { IoMdCheckmark } from "react-icons/io";
import { PiSealCheckFill } from "react-icons/pi";

import InputForm from "../../componet/InputForm";
import Models from "../../componet/Models";
import ReactImageGallery from "react-image-gallery";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getModel } from "../../store/Models/Models.action";
import ModelGalary from "../../componet/models/model-galary/ModelGalary";
import Buttons from "../../componet/Buttons";
import ProfileDetails from "../../componet/profiledetails";
import PostRequest from "../../services/PostRequest";
import moment from "moment";
import { FaRegHeart } from "react-icons/fa";
import { toast } from "react-toastify";
import Loader from "../../componet/theme/Loader";
import ModelPlaceholderImage from "./ModelPlaceholderImage";
import { isAuth } from "../../config/api-urls";
import { AnnouncementBadge } from "../../componet/announcements/AnnouncementBadge";

const AnnouncementDetails = () => {

  const params = useParams();
  const announcementID = params.id;

  const dispatch = useDispatch();

  const [modelGallary, setModelGallary] = useState(false);
  const [modelDetails, setModelDetails] = useState(false);
  const [announcement, setAnnouncement] = useState(false);
  const [userImages, setUserImages] = useState([]);
  

  const loadModelDetails = async (userId) => {
    try {
      const response = await PostRequest("/web/user", { userId: userId });
      setModelDetails(response);
      if(response.userImages && response.userImages.photos){
        setUserImages(response.userImages.photos)
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleFavorite = async () => {

    if(!isAuth()){
      alert('You must be logged in to send a message');
      return false; 
    }

    try {
      var response = await PostRequest("/add-to-favorite", {
        userId: announcement.user?._id,
      });

      toast.success(response);
      // console.log("like", response);
    } catch (error) {}
  };

  const handleMessage = async (event) => {
    event.preventDefault();


    if(!isAuth()){
      alert('You must be logged in to send a message');
      return false; 
    }
    
    var formData = new FormData(event.target);
    formData.append("toUser", announcement.user?._id);
    try {
      const response = await PostRequest("/message/send", formData);
      if(!response) throw new Error('Could not send message');
      toast.success(response);

    } catch (error) {
      toast.error(error);
    }
  };

  const renderSocialMedias = () => {
    return (
      <Fragment>
        {modelDetails.contactDetails?.socialMediaUrls.map((socialMediaUrl) => {
          switch (socialMediaUrl.name) {
            case "Instagram":
              return (
                <div className="ms-3">
                    <a target="__blank" href={socialMediaUrl.link}>
                        <SocialMedia>
                        <FaInstagram color="white" />
                      </SocialMedia>
                    </a>
                </div>
              );

            case "Twitter":
              return (
                <div className="ms-3">
                  <a target="__blank" href={socialMediaUrl.link}>
                  <SocialMedia>
                    <FaXTwitter color="white" />
                  </SocialMedia>
                  </a>
                </div>
              );

            case "WhatsApp":
              return (
                <div className="ms-3">
                  <a target="__blank" href={`https://wa.me/${socialMediaUrl.link}?text=Hello%20%E2%80%9C${modelDetails.name}%E2%80%9D%2C%20I%20found%20your%20profile%20on%20mybella.be`}>
                  <SocialMedia>
                    <FaWhatsapp color="white" />
                  </SocialMedia>
                  </a>
                </div>
              );

            case "Facebook":
              return (
                <div className="ms-3">
                  <a target="__blank" href={socialMediaUrl.link}>
                  <SocialMedia>
                    <FaFacebook color="white" />
                  </SocialMedia>
                  </a>
                </div>
              );

            case "Linkedin":
              return (
                <div className="ms-3">
                  <a target="__blank" href={socialMediaUrl.link}>
                  <SocialMedia>
                    <FaLinkedin color="white" />
                  </SocialMedia>
                  </a>
                </div>
              );

            default:
              return null;
          }
        })}
      </Fragment>
    );
  };

  const loadAnnouncementDetails =  async () => {

    try {

      const response = await PostRequest('/web//get/announcement',{
        announcementId : announcementID
      });
      if(!response) throw new Error('Oops! Something went wrong, Please try again')


      setAnnouncement(response)
      loadModelDetails(response.user?._id);
      
    } catch (error) {
      
    }

  }


  const renderPlanType = () => {

    var type = 'Gold';

    switch(announcement.selectedPlan?.addPlacement){

      case 'PREMIUM' :
        type = 'Premium';
        break;

      case 'VIP' :
        type = 'V.I.P';
        break;

      default :
        type = 'GOLD';

    }


    return <AnnouncementBadge type={type} style={{ position : 'relative' }} />
  }

  useEffect(() => {
    // loadModelDetails();
    loadAnnouncementDetails()
  }, [announcementID]);

  if (!modelDetails || !announcement) {
    return (
      <div className="set-loader">
        <Loader />
      </div>
    );
  }

  return (
    <Fragment>
      <Default>
        <ModelHero title={announcement.title} />
        <div className="models-slider-shortcode space-top">
          <div className="container">
            <div className="row">
              <div
                className="col-md-6 modelDetailsWidth"
                style={{ backgroundColor: "black" }}
              >
                <div
                  onClick={() => {
                    setModelGallary(!modelGallary);
                  }}
                >
                   
                   <div>{renderPlanType()}</div>
                  <ModelGalary photos={[...announcement.photos,...userImages]} />

                  {/* {modelDetails.userImages ? (
                    <ModelGalary extraPhotos={announcement.photos} photos={modelDetails.userImages.photos} />
                  ) : (
                    <ModelPlaceholderImage model={modelDetails} />
                  )} */}
                </div>

                <div className="modelAddress p-5 mb-5">
                  <div className="d-flex justify-content-center">
                    {modelDetails.contactDetails?.socialMediaUrls &&
                      renderSocialMedias()}
                  </div>

                  <div className="mt-5">
                    <p className="addressConteint">
                      {announcement.contactDetails.address}
                    </p>
                    {announcement.user?.email != '' && <p className="addressConteint">{announcement.user?.email}</p>}
                    {announcement.contactDetails.contactPhoneNumber != '' && <p className="addressConteint">{announcement.contactDetails.contactPhoneNumber}</p>}
                    {announcement.contactDetails.website != '' && <p className="addressConteint">
                        <a href={announcement.contactDetails.website} target="__blank">
                          {announcement.contactDetails.website}
                        </a>
                      </p>}
                  </div>

                  {/* <div className="mt-3">
                    <img src={MAP1} style={{ width: "100%" }} />
                  </div> */}
                </div>
              </div>
              <div className="col-md-6 modelDetailsWidth">

                <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <span className="modelDetailsName">{announcement.title}</span>
                        {!modelDetails.isVerified && (
                          <div className="checkMarkBox d-flex align-items-center ms-2">
                            <span>
                              <img src={CHECKMARK} className="checkMark" />
                            </span>
                            <span className="ms-2 check-mark">Verified</span>
                          </div>
                        )}
                      </div>

                </div>
                <div className="container">
                  <div className="d-flex align-items-center justify-content-between sm-display-block">


                      <div style={{width : '60%'}} className="d-flex align-items-center">
                      
                      <button
                        type="button"
                        className="favoriteModel"
                        onClick={handleFavorite}
                      >
                        <FaRegHeart color="#be000d" size={"18px"} /> Favorite
                      </button>
                      </div>

                      <span className="about-user-post">Created By <NavLink target="__blank" className="text-theme" to={`/model/${modelDetails._id}`}> {modelDetails.name} </NavLink>  </span>

                    
                  </div>
                  <p className="modelDetails mt-3">{announcement.description}</p>
                </div>

                <div className="mt-4">
                  <div className="container">
                    <p className="contactTitle">Profile</p>

                    <div className="d-flex justify-content-between mt-3">
                      <ProfileDetails
                        labelName="DOB"
                        profileValue={moment(modelDetails.DOB).format(
                          "DD/MM/YYYY"
                        )}
                      />
                      <ProfileDetails
                        labelName="GENDER"
                        profileValue={modelDetails.gender}
                      />
                      <ProfileDetails
                        labelName="AGE"
                        profileValue={`${modelDetails.age} Years`}
                      />
                    </div>

                    <div className="d-flex mt-3 justify-content-between">
                      <ProfileDetails
                        labelName="NATIONALITY"
                        profileValue={modelDetails.nationality}
                      />
                      <ProfileDetails
                        labelName="Weight"
                        profileValue={modelDetails.weight}
                      />
                      <ProfileDetails
                        labelName="Height"
                        profileValue={`${modelDetails.height} Inches`}
                      />
                    </div>
                    <div className="d-flex  mt-3 justify-content-between">
                      <ProfileDetails
                        labelName="SPOKEN LANGUAGE"
                        profileValue={modelDetails.spokenLanguage}
                      />
                      <ProfileDetails
                        labelName="LOCATION"
                        profileValue="Surat"
                      />
                      <ProfileDetails
                        labelName="ORIGIN"
                        profileValue={modelDetails.original}
                      />
                    </div>

                    <div className="d-flex  mt-3 justify-content-between ">
                      <ProfileDetails
                        labelName="Tattoo(s)"
                        profileValue={modelDetails.tattoos}
                      />
                      <ProfileDetails
                        labelName="Smoking"
                        profileValue={modelDetails.smoking}
                      />
                      <ProfileDetails labelName="" profileValue="" />
                    </div>
                  </div>
                </div>

                {modelDetails.contactDetails && <div className="mt-4">
                  <div className="container">
                    <p className="contactTitle">Availability</p>

                    {modelDetails.contactDetails?.is24HoursOpen ? (
                      <span className="about-user-post"> Available 24/7 </span>
                    ) : (
                      Object.keys(modelDetails.contactDetails.availabilityTiming).map((day, index) => {
                        return(
                          <div className="d-flex gap-5" key={index}>
                            <small
                              className="modelDetails"
                              style={{ width: "17%" }}
                            >
                              {day}
                            </small>
                            <time className="modelDetails">
                              
                              { modelDetails.contactDetails.availabilityTiming[day].isHoliday 
                              ? 'Holiday' 
                              : `${modelDetails.contactDetails.availabilityTiming[day].openTime} - ${modelDetails.contactDetails.availabilityTiming[day].closeTime}` 
                              }
                            </time>
                          </div>
                        )
                      })
                    )}
                  </div>
                </div>
                }

                <div className="mt-4">
                  <div className="container">
                    <p className="contactTitle">Possibility</p>
                    <div className="gap-4">
                      {announcement.services.map(
                        (service, index) => (
                          <div key={index}>
                            <IoMdCheckmark
                              className="me-2"
                              color="gray"
                              size={22}
                            />
                            <span className="about-user-post">{service.service.title}</span>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-5">
                  <div className="container">
                    <form onSubmit={handleMessage}>
                      <p className="contactTitle">Send Message</p>

                      <textarea
                        style={{ width: "100%", height: "200px" }}
                        className="message-box mt-3 p-3 form-input"
                        placeholder="your message"
                        name="message"
                      />

                      <div
                        className="text-end mt-2 mb-5 sm-set-text mr-tab"
                        style={{ marginRight: "85px" }}
                      >
                        <Buttons btnName="Send Message" type="submit" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pb-5">
            <Models />
          </div>
        </div>
        {/* {modelGallary && <div style={{ width: '10%' }}><ReactImageGallery items={images} /></div>} */}
      </Default>
    </Fragment>
  );
};

export default AnnouncementDetails;
