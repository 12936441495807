import React, { Fragment } from "react";
import "animate.css";
import { CHECKMARK, MODELS1 } from "./images";
import { NavLink } from "react-router-dom";
import AboutModel from "./about-model";
import { ASSETS_URL } from "../config/api-urls";
import moment from "moment";

const Model = (props) => {
  // const modelData = props.model
  const { model } = props;

  return (
    <NavLink to={`/model/${model?._id}`}>
      <div className="position-relative overflow-hidden z-1 cursor-pointer media-container ">
        <div className="item-media animate__backInUp ">
          <img src={ASSETS_URL(model?.profile, model.gender )} style={{ height: "100%", objectFit : 'cover'}} />
          <div className="media-links"></div>

          {model.isVerified && (
            <div className="verify-model">
              <img src={CHECKMARK} className="checkMark" />
            </div>
          )}

          {props.children}
        </div>

        <div className="item-content ">
          <h1 className="model-name">{model?.name}</h1>

          <div className="model-parameters">
            {/* <h2 className="first pt-5">HALL</h2> */}

            <div className="container">
              <AboutModel
                values={[
                  {
                    label : 'GENDER',
                    value : model.gender
                  },
                  {
                    label : 'AGE',
                    value : model.age
                  },
                  {
                    label : 'NATIONALITY',
                    value : model.nationality
                  }
                ]}
              />

              <AboutModel

                values={[
                  {
                    label : 'WEIGHT',
                    value : model.weight
                  },
                  {
                    label : 'HEIGHT',
                    value : model.height
                  },
                  {
                    label : 'TATTOO',
                    value : model.tattoos
                  }
                ]}

              />

              <AboutModel
                values={[
                  {
                    label : 'LANGUAGES',
                    value : model.spokenLanguage
                  },
                  {
                    label : 'ORIGIN',
                    value : model.origin
                  },
                  {
                    label : 'SMOKING',
                    value : model.smoking
                  }
                ]}
              
              />
            </div>
          </div>
        </div>
      </div>
    </NavLink>
    // <div></div>
  );
};

export default Model;
