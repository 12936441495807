import React, { Component, Fragment, useEffect, useLayoutEffect, useState } from "react";

import "./App.css";

import Home from "../src/pages/Home/Home";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Menu from "./pages/menu/Menu";

import ModelsPage from "./pages/modelsPage/ModelsPage";
// import Blog from './pages/Blog/Blog';
import Register from "./pages/register/Register";
import About from "./pages/about/About";
import Contacts from "./pages/contacts/Contacts";
import Faq from "./componet/faq/Faq";
import Address from "./pages/address/Address";
import Modeldetails from "./pages/model-details/Modeldetails";
import { Provider } from "react-redux";
import { applyMiddleware, combineReducers, legacy_createStore } from "redux";
import modelsReducer from "./store/Models/Models.reducer";
import { thunk } from "redux-thunk";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AnnouncementDetails from "./pages/model-details/AnnouncementDetails";
import { Modal, Button } from 'react-bootstrap';

import "./responsive.css";

// Reducers
const rootReducers = combineReducers({
  modelsReducer: modelsReducer,
});

// Store

const store = legacy_createStore(rootReducers, applyMiddleware(thunk));


const FirstTimeModal = ({ show, onHide }) => {

  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleConfirm = () => {
    setIsConfirmed(true);
    localStorage.setItem('visitedBefore', true);
    onHide();
  };

  const handleExit = () => {
    localStorage.setItem('visitedBefore', false);
    window.location.reload();
  }

  return (
    <Modal show={show} onHide={onHide} centered>
    <Modal.Header closeButton>
      <Modal.Title>Age Verification</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {!isConfirmed ? (
        <>
          <p>This website contains explicit adult content. To access the site, you must verify that you are 18 years of age or older.</p>
          <p>By clicking "Confirm," you acknowledge that you are of legal age.</p>
        </>
      ) : (
        <p>Thank you for verifying your age. Enjoy your visit!</p>
      )}
    </Modal.Body>
    <Modal.Footer>
      {!isConfirmed ? (
        <Button variant="dark" onClick={handleConfirm}>
          Confirm
        </Button>
      ) : null}
      <Button variant="secondary" onClick={handleExit}>
        Exit
      </Button>
    </Modal.Footer>
  </Modal>
  );
};

const App = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    var isFirstTime = false;
    if(localStorage.getItem('visitedBefore') !== null && localStorage.getItem('visitedBefore') == true){
      isFirstTime = true
    }
    if (isFirstTime) {
      setShowModal(true);
    }
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const handleCloseModal = () => setShowModal(false);

  return (
    <Provider store={store}>
      {/* <Home/>
      <Menu /> */}

      <BrowserRouter basename="/web">
        <Routes>
          <Route path="/menu" element={<Menu />} />
          <Route path="/home" element={<Home />} />
          <Route path="/home/:category" element={<Home />} />
          <Route path="/home/:category/:subCategory" element={<Home />} />
          
          <Route path="/models" element={<ModelsPage />} />
          <Route path="/models/private/:category" element={<ModelsPage />} />


          <Route
            path="/models/lib-categores/:category"
            element={<ModelsPage />}
          />
          <Route path="/jobs" element={<ModelsPage />} />
          <Route path="/" element={<Home />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/register" element={<Register />} />
          <Route path="/about" element={<About />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/address" element={<Address />} />
          <Route path="/model/:id" element={<Modeldetails />} />
          <Route path="/announcement/:id" element={<AnnouncementDetails />} />
        </Routes>
      </BrowserRouter>
      <FirstTimeModal show={showModal} onHide={handleCloseModal} />
      <ToastContainer />
    </Provider>
  );
};

export default App;
