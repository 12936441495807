import PostRequest from "../../services/PostRequest"

export const register = async (data) => {

    try {
        

        var response = await PostRequest("/user/register",data)

        return response;
    } catch (error) {
        
    }

}