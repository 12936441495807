

import React, { Component, Fragment, useEffect, useState } from "react";
import "animate.css";
import { useDispatch, useSelector } from "react-redux";
import { getAnnouncementsFromAPI, getPremiumAnnouncementsFromAPI } from "../../store/Models/Models.action";
import AnnouncementItem from "./AnnouncementItem";
import Buttons from "../Buttons";
import "./announcement.css";
import NavbarItems from "../NavbarItems";
import PremiumAnnouncementSwiper from "./PremiumAnnouncementSwiper";

import './premium-slider.css'

const Announcements = () => {

  const _announcements = useSelector((state) => state.modelsReducer.announcements);

  const [skip, setSkip] = useState(0)

  const renderAdBanner = (skip = 0) =>(
    <div className="pt-4 premium-wrapper">
      <div className="pb-1"> 
        <h4 className="text-theme p-2"> Premium Ads </h4>
      </div>
      <PremiumAnnouncementSwiper skip={skip} />
    </div>
  );


  const handleLoadMore = () => {
    setSkip( prev => prev + 48 )
    console.log('HERE', skip)

  }

  
  return (
    <div className="all-models-wrapper">
    <NavbarItems skip={skip} />


    {
      _announcements?.length !== 0 ? <div className="row mt-5  handle-box">
        {_announcements.map((announcement,index) => {

          const skip = index === 0 ? 0 : (index - 1) + (Math.floor((index - 1) / 7));


          return(
            <React.Fragment key={index}>
            {index > 0 && index % 8 === 0 && renderAdBanner(skip)}
            <div className="col-md-3 mt-3 modelWidth">
              <AnnouncementItem model={announcement.user} announcement={announcement} />
            </div>
            </React.Fragment>
          )
        })}
      </div> : <div className="text-center mt-5 blankArrayText">No result found !!!</div>
    }


    <div className="mt-5 text-center pb-5">
       <button  onClick={handleLoadMore} className="btn-schedule in-btn-txt" >
          View More
        </button>
    </div>


  </div>
  );


};

export default Announcements;
