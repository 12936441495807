import React, { useEffect, useState } from "react";
import Default from "../../componet/theme/Default";
import ModelHero from "../../componet/ModelHero";
import "./address.css";
import { CASTING } from "../../componet/images";
import SocialMedia from "../../componet/SocialMedia";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import AddressImg from "../../componet/address/AddressImg";
import AddressDetails from "../../componet/address/AddressDetails";
import AddressDetails2 from "../../componet/address/AddressDetails2";
import PostRequest from "../../services/PostRequest";
import Loader from "../../componet/theme/Loader";
import { ASSETS_URL } from "../../config/api-urls";

const Address = () => {
  const [addressData, setAddressData] = useState([]);

  console.log("response", addressData);

  const [loader, setLoader] = useState(false);

  const loadAddressData = async (data = {},isLoadMore = false ) => {
    try {



      const response = await PostRequest("/web/address",data);

      if (response) {
        setLoader(response);

        if(!isLoadMore){
          setAddressData(response);
        } else {
          setAddressData( prev => [...prev, ...response] );
        }

      } else {
      }
    } catch (error) {
      alert(error);
    }
  };


  const handleLoadMore = () => {
    const data = {
      skip : addressData.length,
      limit : 10
    }
    loadAddressData(data,true);
  }

  useEffect(() => {
    const data = {
      limit : 10
    }
    loadAddressData(data);
  }, []);


  if (!loader) {
    return (
      <div className="set-loader">
        <Loader />
      </div>
    );
  }

  return (
    <Default hasGap="5%">
      <ModelHero title="Address" />

      {addressData?.map((address, index) => (
        <div className="rel" key={index}>
          <div className="bg-casting">

            <div className="container mb-5">
              <div className="row">
                <div className="col-md-4 mb-5 modelDetailsWidth">
                  <AddressImg path={ASSETS_URL(address.profilePhoto)} />
                </div>

                <div className="col-md-8 pt-4 modelDetailsWidth tabAddress">
                  <AddressDetails
                    announcement={address}
                    description={address.description}
                    title={address.title}
                  />
                </div>
                
              </div>
            </div>


           
          </div>
        </div>
      ))}


      <div className="mt-5 text-center pb-5">
        <button  onClick={handleLoadMore} className="btn-schedule in-btn-txt" >
            View More
          </button>
      </div>
    </Default>
  );
};

export default Address;
