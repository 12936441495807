import React, { Fragment } from "react";
import Default from "../theme/Default";
import ModelHero from "../ModelHero";
import "./Faq.css";

const Faq = () => {
  return (
    <Fragment>
      <Default >
        <ModelHero title="Faq" />

        <div className="rel">
          <div className="bg-faq">
            <div className="container mt-5 p-5 sm-mt-set">
              <h1 className="special-heading ">General Questions</h1>

              <div className="accordion pt-3" id="accordionExample">
                <div className="accordion-item ">
                  <h2 className="accordion-header ">
                    <button
                      className="accordion-button panel-title "
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Accordion Item #1
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body panel-body">
                      <p>
                        Et pri affert debitis alienum, libris impetus ex ludus
                        ex democritum adolescens mea et, te quot has mundi
                        posidonium has.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed panel-title"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Accordion Item #2
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body panel-body">
                      Et pri affert debitis alienum, libris impetus ex ludus ex
                      democritum adolescens mea et, te quot has mundi posidonium
                      has.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed panel-title"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Accordion Item #3
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body panel-body">
                      Et pri affert debitis alienum, libris impetus ex ludus ex
                      democritum adolescens mea et, te quot has mundi posidonium
                      has.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Default>
    </Fragment>
  );
};

export default Faq;
