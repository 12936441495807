import React from 'react'
import { RiVipCrownFill } from "react-icons/ri";
import { FaHeart } from "react-icons/fa";
import { FaStar } from "react-icons/fa6";
import { MdOutlineVerified } from "react-icons/md";


export const AnnouncementBadge = (props) => {

 const {type = 'GOLD',showVerified = false, style={}} = props;



 const renderBadge = () => {

    if(type === 'V.I.P') {
        return <div className='announcement-badge vip-badge' style={style}> <RiVipCrownFill className='me-2' /> V.I.P</div>
    }

    if(type === 'Premium') {
        return <div className='announcement-badge premium-badge' style={style}> <FaHeart className='me-2' /> Premium</div>
    }

    return (
        <div className='announcement-badge gold-badge' style={style}><FaHeart className='me-2' /> GOLD</div>
    )
 }


  return(
    <div className='announcement-badge-wrapper d-flex justify-content-between align-items-center'>
        {renderBadge()}
        {showVerified && <MdOutlineVerified size={35} color='#1f88ff' />}
    </div>
  )
}
