import React, { Fragment, useLayoutEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";

const Default = (props) => {
  const { isFooterVisible = true, hasGap = false } = props;

  const location = useLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Fragment>
      <Header />

      {props.children}

      {/* Use ?: for conditionally display element instead of && at every place */}
      {isFooterVisible ? (
        <div
          style={{ marginTop: hasGap ? hasGap : "0px" }}
          className={`${hasGap ? "sm-footer-set" : ""}`}
        >
          <Footer />
        </div>
      ) : null}
    </Fragment>
  );
};

export default Default;
