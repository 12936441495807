const initialState = {
  verifiedModels: [],
  models: [],
  announcements: [],
  announcementsPremium: [],
};

export const modelsReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case "GET_MODELS":
      return { ...state, models: actions.value };

    case "GET_ANNOUNCEMENT":
      return { ...state, announcements: actions.value };

    case "GET_MORE_MODELS":
      return { ...state, models: [...state.models, ...actions.value] };

    case "LOAD_MORE_ANNOUNCEMENT":
      return { ...state, announcements: [...state.announcements, ...actions.value] };

    case "GET_ANNOUNCEMENT_PREMIUM":
      return { ...state, announcementsPremium: actions.value };
        
    case "LOAD_MORE_ANNOUNCEMENT_PREMIUM":
      return { ...state, announcementsPremium: [...state.announcementsPremium, ...actions.value] };
  
    default:
      return state;
  }
};

export default modelsReducer;
