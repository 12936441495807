import React, { Fragment, useEffect, useState } from "react";
import Default from "../../componet/theme/Default";
import Model from "../../componet/Model";
import ModelHero from "../../componet/ModelHero";
import "./ModelDetails.css";
import {
  CHECKMARK,
  MAP1,
  MODEL12,
  MODEL14,
  MODEL3,
  MODEL7,
  MODELS1,
} from "../../componet/images";
import SocialMedia from "../../componet/SocialMedia";
import { FaWhatsapp } from "react-icons/fa6";

import { FaXTwitter } from "react-icons/fa6";

import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import { FaRegStar } from "react-icons/fa";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { IoMdCheckmark } from "react-icons/io";
import { PiSealCheckFill } from "react-icons/pi";

import InputForm from "../../componet/InputForm";
import Models from "../../componet/Models";
import ReactImageGallery from "react-image-gallery";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getModel } from "../../store/Models/Models.action";
import ModelGalary from "../../componet/models/model-galary/ModelGalary";
import Buttons from "../../componet/Buttons";
import ProfileDetails from "../../componet/profiledetails";
import PostRequest from "../../services/PostRequest";
import moment from "moment";
import { FaRegHeart } from "react-icons/fa";
import { toast } from "react-toastify";
import Loader from "../../componet/theme/Loader";
import ModelPlaceholderImage from "./ModelPlaceholderImage";
import { isAuth } from "../../config/api-urls";

const Modeldetails = () => {
  const times = [
    {
      day: "Monday",
      open: "11:00",
      close: "17:00",
    },
    {
      day: "Tuesday",
      open: "11:00",
      close: "17:00",
    },
    {
      day: "Wednesday",
      open: "11:00",
      close: "17:00",
    },
    {
      day: "Thursday",
      open: "11:00",
      close: "17:00",
    },
    {
      day: "Friday",
      open: "11:00",
      close: "17:00",
    },
    {
      day: "Saturday",
      open: "11:00",
      close: "17:00",
    },
    {
      day: "Sunday",
      open: "11:00",
      close: "17:00",
      isHoliday: "Not Available",
    },
  ];

  const params = useParams();
  const modelID = params.id;

  const dispatch = useDispatch();

  const [modelGallary, setModelGallary] = useState(false);
  const [modelDetails, setModelDetails] = useState(false);

  const images = [
    {
      original: MODELS1,
      // thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
      original: MODEL7,
      // thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
      original: MODEL12,
      // thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
      original: MODEL14,
      // thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
  ];

  const loadModelDetails = async () => {
    try {
      const response = await PostRequest("/web/user", { userId: modelID });
      setModelDetails(response);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleFavorite = async () => {
    try {

      if(!isAuth()){
        toast.error('You must be login first');
        return false;
      }

      var response = await PostRequest("/add-to-favorite", {
        userId: modelID,
      });

      toast.success(response);

      // console.log("like", response);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleMessage = async (event) => {
    event.preventDefault();


    if(!isAuth()){
      toast.error('You must be login first');
      return false;
    }

    var formData = new FormData(event.target);

    formData.append("toUser", modelID);

    try {
      const response = await PostRequest("/message/send", formData);
      toast.success(response);

      console.log("res", response);
    } catch (error) {
      toast.error(error);
    }
  };

  const renderSocialMedias = () => {
    return (
      <Fragment>
        {modelDetails.contactDetails?.socialMediaUrls.map((socialMediaUrl) => {
          switch (socialMediaUrl.name) {
            case "Instagram":
              return (
                <div className="ms-3">
                  <a target="__blank" href={socialMediaUrl.link}>
                    <SocialMedia>
                      <FaInstagram color="white" />
                    </SocialMedia>
                  </a>
                </div>
              );

            case "Twitter":
              return (
                <div className="ms-3">
                  <a target="__blank" href={socialMediaUrl.link}>
                    <SocialMedia>
                      <FaXTwitter color="white" />
                    </SocialMedia>
                  </a>
                </div>
              );

            case "WhatsApp":
              return (
                <div className="ms-3">
                  <a target="__blank" href={socialMediaUrl.link}>
                    <SocialMedia>
                      <FaWhatsapp color="white" />
                    </SocialMedia>
                  </a>
                </div>
              );

            case "Facebook":
              return (
                <div className="ms-3">
                  <a target="__blank" href={socialMediaUrl.link}>
                    <SocialMedia>
                      <FaFacebook color="white" />
                    </SocialMedia>
                  </a>
                </div>
              );

            case "Linkedin":
              return (
                <div className="ms-3">
                  <a target="__blank" href={socialMediaUrl.link}>
                    <SocialMedia>
                      <FaLinkedin color="white" />
                    </SocialMedia>
                  </a>
                </div>
              );

            default:
              return null;
          }
        })}
      </Fragment>
    );
  };

  // const loadModelDetails = () => {

  // }

  useEffect(() => {
    loadModelDetails();
  }, []);

  if (!modelDetails) {
    return (
      <div className="set-loader">
        <Loader />
      </div>
    );
  }

  return (
    <Fragment>
      <Default>
        <ModelHero title={modelDetails.name} />
        <div className="models-slider-shortcode space-top">
          <div className="container">
            <div className="row">
              <div
                className="col-md-6 modelDetailsWidth"
                style={{ backgroundColor: "black" }}
              >
                <div
                  onClick={() => {
                    setModelGallary(!modelGallary);
                  }}
                >
                  {modelDetails.userImages ? (
                    <ModelGalary photos={modelDetails.userImages.photos} />
                  ) : (
                    <ModelPlaceholderImage model={modelDetails} />
                  )}
                </div>

                <div className="modelAddress p-5 mb-5">
                  <div className="d-flex justify-content-center">
                    {modelDetails.contactDetails?.socialMediaUrls &&
                      renderSocialMedias()}
                  </div>

                  <div className="mt-5">
                    <p className="addressConteint">{modelDetails.email}</p>
                    <p className="addressConteint borderBottem pb-4">
                      {modelDetails.phoneNumber}
                    </p>
                  </div>
{/* 
                  <div className="mt-3">
                    <img src={MAP1} style={{ width: "100%" }} />
                  </div> */}
                </div>
              </div>
              <div className="col-md-6 modelDetailsWidth">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="modelDetailsName">{modelDetails.name}</span>
                    {!modelDetails.isVerified && (
                      <div className="checkMarkBox d-flex align-items-center ms-2">
                        <span>
                          <img src={CHECKMARK} className="checkMark" />
                        </span>
                        <span className="ms-2 check-mark">Verified</span>
                      </div>
                    )}
                  </div>

                  <div className="d-flex flex-row-reverse sm-display-block">
                    <button
                      type="button"
                      className="favoriteModel"
                      onClick={handleFavorite}
                    >
                      <FaRegHeart color="#be000d" size={"18px"} /> Favorite
                    </button>
                  </div>
                </div>

                <span className="about-user-post"> {modelDetails.age} Years </span>
                
                <div className="mt-4">
                  <div className="container">
                    <p className="contactTitle">Profile</p>

                    <div className="d-flex justify-content-between mt-3">
                      <ProfileDetails
                        labelName="DOB"
                        profileValue={moment(modelDetails.DOB).format(
                          "DD/MM/YYYY"
                        )}
                      />
                      <ProfileDetails
                        labelName="GENDER"
                        profileValue={modelDetails.gender}
                      />
                      <ProfileDetails
                        labelName="AGE"
                        profileValue={`${modelDetails.age} Years`}
                      />
                    </div>

                    <div className="d-flex mt-3 justify-content-between">
                      <ProfileDetails
                        labelName="NATIONALITY"
                        profileValue={modelDetails.nationality}
                      />
                      <ProfileDetails
                        labelName="Weight"
                        profileValue={modelDetails.weight}
                      />
                      <ProfileDetails
                        labelName="Height"
                        profileValue={`${modelDetails.height} Inches`}
                      />
                    </div>
                    <div className="d-flex  mt-3 justify-content-between">
                      <ProfileDetails
                        labelName="SPOKEN LANGUAGE"
                        profileValue={modelDetails.spokenLanguage}
                      />
                      <ProfileDetails
                        labelName="LOCATION"
                        profileValue="Surat"
                      />
                      <ProfileDetails
                        labelName="ORIGIN"
                        profileValue={modelDetails.original}
                      />
                    </div>

                    <div className="d-flex  mt-3 justify-content-between ">
                      <ProfileDetails
                        labelName="Tattoo(s)"
                        profileValue={modelDetails.tattoos}
                      />
                      <ProfileDetails
                        labelName="Smoking"
                        profileValue={modelDetails.smoking}
                      />
                      <ProfileDetails labelName="" profileValue="" />
                    </div>
                  </div>
                </div>

                {modelDetails.contactDetails && <div className="mt-4">
                  <div className="container">
                    <p className="contactTitle">Availability</p>

                    {modelDetails.contactDetails?.is24HoursOpen ? (
                      <span className="about-user-post"> Available 24/7 </span>
                    ) : (
                      Object.keys(modelDetails.contactDetails.availabilityTiming).map((day, index) => {
                        return(
                          <div className="d-flex gap-5" key={index}>
                            <small
                              className="modelDetails"
                              style={{ width: "17%" }}
                            >
                              {day}
                            </small>
                            <time className="modelDetails">
                              
                              { modelDetails.contactDetails.availabilityTiming[day].isHoliday 
                              ? 'Holiday' 
                              : `${modelDetails.contactDetails.availabilityTiming[day].openTime} - ${modelDetails.contactDetails.availabilityTiming[day].closeTime}` 
                              }
                            </time>
                          </div>
                        )
                      })
                    )}
                  </div>
                </div>
                }

                {/* <div className="mt-4">
                  <div className="container">
                    <p className="contactTitle">Possibility</p>
                    <div className="gap-4">
                      <div className="sub-title mb-2"> MASSAGE </div>

                      {["Body to Body", "Hot stone massage", "Massage"].map(
                        (massage, index) => (
                          <div key={index}>
                            <IoMdCheckmark
                              className="me-2"
                              color="gray"
                              size={22}
                            />
                            <span className="about-user-post">{massage}</span>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div> */}

                <div className="mt-5">
                  <div className="container">
                    <form onSubmit={handleMessage}>
                      <p className="contactTitle">Send Message</p>

                      <textarea
                        style={{ width: "100%", height: "200px" }}
                        className="message-box mt-3 p-3 form-input"
                        placeholder="your message"
                        name="message"
                      />

                      <div
                        className="text-end mt-2 mb-5 sm-set-text mr-tab"
                        style={{ marginRight: "85px" }}
                      >
                        <Buttons btnName="Send Message" type="submit" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pb-5">
            <Models />
          </div>
        </div>
        {/* {modelGallary && <div style={{ width: '10%' }}><ReactImageGallery items={images} /></div>} */}
      </Default>
    </Fragment>
  );
};

export default Modeldetails;
