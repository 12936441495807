import React from "react";
import { API_URL } from "../config/api-urls";
import Axios from "axios";
import { toast } from "react-toastify";

const PostRequest = async (path, data = {}) => {
  const token = window.localStorage.getItem("token");
  // console.log("path", path);
  const header = {
    "content-type": "application/json",
    "X-Authentication-token":token,
  };

  

  try {
    const response = await Axios.post(`${API_URL}${path}`, data, {
      headers: header,
      crossDomain: true,
    });

    const responseData = response.data;
    if (responseData.code == 200) {
      // throw new Error(responseData.data);
      return responseData.data;
    } else {
      toast.error(responseData.data);
    }

  } catch (error) {
    toast.error(error);
  }

  // return <div></div>;
};

export default PostRequest;
