import React, { Fragment, useEffect, useState } from "react";
import { SIGNATURE } from "../images";
import Buttons from "../Buttons";
import "./home-casting.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import PostRequest from "../../services/PostRequest";
import { ASSETS_URL,formatPhoneNumberForWhatsApp } from "../../config/api-urls";
import { NavLink } from "react-router-dom";
import { AnnouncementBadge } from "../announcements/AnnouncementBadge";
import WhatsAppButton from "../WhatsAppButton";
import ExpandedAnnouncement from "../announcements/ExpandedAnnouncement";



const AboutModel = () => {
  const [announcements, setAnnouncements] = useState([]);
  const loadAnnounceMent = async () => {
    try {
      const data = {
        addPlacement: "VIP",
      };
      const response = await PostRequest("/web/announcements", data);
      setAnnouncements(response);
    } catch (error) {}
  };

  useEffect(() => {
    loadAnnounceMent();
  }, []);

  return (
    <div className="about-bg">
      <div className="vip-announcement-wrapper">
        <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
          {announcements?.map((announcement, index) => (
            <SwiperSlide key={index}>
                <ExpandedAnnouncement showWebUrl={true} announcement={announcement} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default AboutModel;
