import React, { Fragment, useEffect, useState, useRef } from "react";
import { LOGO2 } from "../images";
import { LuLogIn } from "react-icons/lu";
import { FaRegUser } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";
import { NavLink, useParams } from "react-router-dom";
import Login from "../../pages/Login/Login";
import Register from "../../pages/register/Register";
import Menu from "../../pages/menu/Menu";
import "./default.css";
import ModelsPage from "../../pages/modelsPage/ModelsPage";
import { useMediaQuery } from "@uidotdev/usehooks";
import TranslateDropdown from "../TranslateDropdown";
import { isAuth } from "../../config/api-urls";


const Header = () => {


  const location = useParams();

  const [loginPage, setLoginPage] = useState(false);
  const [registerForm, setRegisterForm] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const modalRef = useRef(null);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setLoginPage(false);
      setRegisterForm(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    document.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const closeLoginModel = () => {
    setLoginPage(false);
  };

  const closeRegisterModel = () => {
    setRegisterForm(false);
  };


  useEffect(() => {

    if(isMenuOpen){
      setMenuOpen(false);
    }

  },[location])

  return (
    <Fragment>
      <div
        className={`header-set container-fluid header-mask bg-black`}
      >
        <div className=" ps-4 pe-4">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <NavLink to={"/home"}>
                <img src={LOGO2} className="logo" />
              </NavLink>
            </div>

            <div className="gap-4 d-flex justify-content-between align-items-center set-header">
              <TranslateDropdown />
              <div className="gap-4 d-flex">
                
                {
                  !isAuth() 
                    ? <Fragment>
                      <LuLogIn
                        color="white"
                        size={"20px"}
                        className="headerIconsNav"
                        onClick={(e) => {
                          e.stopPropagation();
                          setLoginPage(!loginPage);
                          setRegisterForm(false);
                        }}
                      />
                      <FaRegUser
                        color="white"
                        size={"18px"}
                        className="headerIconsNav"
                        onClick={(e) => {
                          e.stopPropagation();
                          setRegisterForm((prevState) => !prevState);
                          setLoginPage(false);
                        }}
                      />
                      </Fragment> 
                    :  <a href='/account'>
                        <FaRegUser
                          color="white"
                          size={"18px"}
                          className="headerIconsNav"
                        />
                    </a>
                }

                {/* <NavLink to={"/models"}>
                  <IoIosSearch
                    color="white"
                    size={"20px"}
                    className="headerIconsNav"
                    style={{ marginTop: "-6px" }}
                  />
                </NavLink> */}
              </div>
              <span
                className={`toggle_menu_side header-slide ${
                  isMenuOpen ? "active-slide-side-header" : ""
                }`}
                onClick={() => {
                  setMenuOpen(!isMenuOpen);
                }}
              >
                <span></span>
              </span>
            </div>
          </div>
        </div>
      </div>

      {loginPage && (
        <div ref={modalRef}>
          <Login closeLogin={closeLoginModel} />
        </div>
      )}
      {registerForm && (
        <div ref={modalRef}>
          <Register closeRegister={closeRegisterModel} />
        </div>
      )}
      {isMenuOpen && <Menu />}
    </Fragment>
  );
};

export default Header;
