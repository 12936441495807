import React from "react";
// import { PHOTOGRAPHER } from "../images";
import "./photographer.css";
import { PHOTOGRAPHER } from "../images";

const Photograph = () => {
  return (
    <div className="rel">
      <div className="photograph-bg">
        <div className="overlay">
          {/* <div className="givePadding"></div> */}

          <div className="avatar text-center pt-5">
            <img src={PHOTOGRAPHER} />

            <div className="mt-4 author">
              <h3 className="author-name ">Wayne Fernandez</h3>
              <p className="author-job">Photographer</p>

              <p className="block-content pt-3">
                Truly one of my favorite agencies. Your talent is so great and
                your agency is so professional. It’s such a pleasure to come
                there! Thank you for being a company that I count on!
              </p>

              <div className="mt-5 sm-margin res-mt">
                <span className="prev pe-2">Prev</span>
                <span style={{ fontSize: "18px", color: "white" }}>/</span>
                <span className="prev ps-2">Next</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Photograph;
