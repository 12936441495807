import React from "react";
import SocialMedia from "../SocialMedia";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

const AddressDetails = (props) => {

  const {announcement} = props;

  return (
    <div>
      <div className="d-flex content-col-casting">
        <div>
          <h4 className="user-name">{props.title}</h4>

          <div className="excerpt">
            <span className="post-of-user">DESCRIPTION </span>
            <div>
              {props.description}
            </div>
          </div>

          <div className="excerpt mt-3">
            <span className="post-of-user">ADDRESS </span>
            <div>
              {announcement.contactDetails?.address}
            </div>
          </div>

          <div className="excerpt mt-3">
            <span className="post-of-user">Contact Number </span>
            <div>
              {announcement.contactDetails?.contactPhoneNumber}
            </div>
          </div>
        
        </div>
        {/* <div className="ms-5 mt-4">
          <SocialMedia>
            <FaFacebookF color="white" />
          </SocialMedia>
          <div className="mt-2">
            <SocialMedia>
              <FaTwitter color="white" />
            </SocialMedia>
          </div>

          <div className="mt-2">
            <SocialMedia>
              <FaInstagram color="white" />
            </SocialMedia>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default AddressDetails;
