import React, { Component, Fragment, useEffect } from "react";
import "animate.css";
import Model from "./Model";
import { useDispatch, useSelector } from "react-redux";

const Models = () => {
  //   const dispatch = useDispatch();
  const _models = useSelector((state) => state.modelsReducer.verifiedModels);

  return (
    <div className="container-fluid">
      <div className="row me-0 ms-2">
        {_models.map((model, index) => (
          <div className="col-md-3" key={index}>
            <Model model={model} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Models;
