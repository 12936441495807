import React, { Fragment, useRef, useState } from "react";
import "./Login.css";
import PostRequest from "../../services/PostRequest";
import { useNavigate } from "react-router-dom";
import { doLogin } from "../../store/login/login.action";
import { Formik } from "formik";
import { toast } from "react-toastify";
import Loader from "../../componet/theme/Loader";
import { LineWave } from "react-loader-spinner";

const Login = (props) => {
  const [isLoader, setIsLoader] = useState(false);


  const handleLoginData = async (event) => {
    var data = new FormData(event.target);
    event.preventDefault();

    try {
      setIsLoader(true);

      const response = await PostRequest("/user/login", data);

      if (response) {
        console.log('res-login', response);
        window.localStorage.setItem("token", response.token);
        window.localStorage.setItem("authID", response._id);
        toast.success("Login SuccessFully");
        window.location.href = '/account';
        props.closeLogin();
      }
    } catch (error) {
      toast.error(error);
    }
    setIsLoader(false);
  };

  return (
    <Fragment>
      <div className="dropdownMenu dropdownMenu-login">
        <Formik
          initialValues={{ email: "", password: "" }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Required";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            console.log("values", values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleLoginData}>
              <p className="input-title">Email</p>

              <input
                type=" text"
                className="form-input"
                placeholder="login"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              {errors.email && touched.email && errors.email}

              <div className="mt-3">
                <p className="input-title">password</p>

                <input
                  type="password"
                  className="form-input"
                  placeholder="password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
                {errors.password && touched.password && errors.password}
              </div>

              <div className="mt-2 d-flex gap-2">
                <input type="checkbox" />
                <label className="checkbox-remeber">Remember Me</label>
              </div>

              {/* <button
                className="btn-login mt-3"
                type="submit"
                disabled={isSubmitting}
              >
                {isLoader ? (
                  <div className="loader-set ms-3">
                    <LineWave
                      visible={true}
                      height="100"
                      width="100"
                      color="black"
                      ariaLabel="line-wave-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      firstLineColor=""
                      middleLineColor=""
                      lastLineColor=""
                    />
                  </div>
                ) : (
                  "log in"
                )}
              </button> */}


              {
                isLoader ? <button
                  className="btn-login mt-3"
                  type="submit"
                  // disabled={isSubmitting}
                  disabled
                >
                  <div className="loader-set ms-3">
                    <LineWave
                      visible={true}
                      height="100"
                      width="100"
                      color="black"
                      ariaLabel="line-wave-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      firstLineColor=""
                      middleLineColor=""
                      lastLineColor=""
                    />
                  </div>
                </button> : <button
                  className="btn-login mt-3"
                  type="submit"
                  disabled={isSubmitting}
                >
                  log in
                </button>
              }

              <p className="forgot-link mt-3">Forgot Your Password?</p>
            </form>
          )}
        </Formik>
      </div>
    </Fragment>
  );
};

export default Login;
