import React, { Fragment, useState } from "react";
import "./menu.css";
import { FaChevronRight } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa6";
import { NavLink } from "react-router-dom";

const Menu = () => {
  const [pagesOption, setPagesOption] = useState(false);
  const [libCategoriesOption, setLibCategoriesOption] = useState(false);

  const pagesOptions = () => {
    setPagesOption(!pagesOption);
  };
  const libCategoriesOptions = () => {
    setLibCategoriesOption(!libCategoriesOption);
  };

  return (
    <Fragment>
      <div style={{ overflowY: "hidden" }}>
        <div className="menuBg">
          <NavLink to={"/home"}>
            <span className="menuItem menuItem-header">HOME</span>
            <br></br>
          </NavLink>

          <div>
            <span
              onClick={pagesOptions}
              className={`menuItem menuItem-header ${pagesOption && "menu-item-active"}`}
            >
              PRIVATE {(pagesOption && (
              <FaChevronDown size={"20px"} color="#be000d" />
            )) || (
              <span>
                <FaChevronRight size={"20px"} color="#fff" />
              </span>
            )}
            </span>

            
          </div>

          {pagesOption && (
            <div  onClick={pagesOptions}>
              <NavLink to={"/home/Private/Girl"}>
                <p className="homeOptions">Girl</p>
              </NavLink>
              <NavLink to={"/home/Private/Men"}>
                <p className="homeOptions">Man</p>
              </NavLink>
              <NavLink to={"/home/Private/Couples"}>
                <p className="homeOptions">Couples</p>
              </NavLink>
              <NavLink to={"/home/Private/Trans"}>
                <p className="homeOptions">Fetish</p>
              </NavLink>
            </div>
          )}

<div onClick={libCategoriesOptions}>
            <span
              className={`menuItem  menuItem-header ${
                libCategoriesOption && "menu-item-active"
              }`}
            >
              ESCORT  {(libCategoriesOption && (
              <span>
                <FaChevronDown size={"20px"} color="#be000d" />
              </span>
            )) || (
              <span>
                <FaChevronRight size={"20px"} color="#fff" />
              </span>
            )}
            </span>

           
          </div>

          {libCategoriesOption && (
            <div>
               <NavLink to={"/home/Escort/Girl"}>
                <p className="homeOptions">Girl</p>
              </NavLink>
              <NavLink to={"/home/Escort/Men"}>
                <p className="homeOptions">Man</p>
              </NavLink>
              <NavLink to={"/home/Escort/Couple"}>
                <p className="homeOptions">Couples</p>
              </NavLink>
              <NavLink to={"/home/Escort/Trans"}>
                <p className="homeOptions">Fetish</p>
              </NavLink>
            </div>
          )}

        
          <NavLink to={"/home/Others/Job"}>
            
            <div>
              <span className="menuItem menuItem-header">JOBS</span>
            </div>

          </NavLink>


          <NavLink to={"/address"}>
            
            <div>
              <span className="menuItem menuItem-header">ADDRESS</span>
            </div>

          </NavLink>

          <NavLink to={"/faq"}>
            
            <div>
              <span className="menuItem menuItem-header">FAQ</span>
            </div>

          </NavLink>
          {/* <NavLink to={"/contacts"}>
            
            <div>
              <span className="menuItem menuItem-header">CONTACTS</span>
            </div>

          </NavLink> */}
          {/* <NavLink to={"/about"}>
            <p className="menuItem menuItem-header">ABOUT</p>
          </NavLink> */}
        </div>
      </div>
    </Fragment>
  );
};

export default Menu;
