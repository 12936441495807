import React from "react";

const ProfileDetails = (props) => {
  return (
    <div className="" style={{ width: "100%" }}>
      <label className="profile-header-name">{props.labelName}</label>
      <p className="about-user-post">{props.profileValue}</p>
    </div>
  );
};

export default ProfileDetails;
