import React from "react";

const AboutModel = (props) => {
  //   console.log("props", props);

  const {values = []} = props;

  return (
    <div className="d-flex justify-content-between mt-2 pt-2">

      {

        values.map( (item,index) => {
         
          return(
            <div key={index} className="paramiterMain">
              <p className="parameter-title">{item.label}</p>
              <p className="parameter-value parameter-title">{item.value}</p>
            </div>
          )

        } )

      }

      {/* <div className="paramiterMain">
        <p className="parameter-title">{props.labelTwo}</p>
        <p className="parameter-value parameter-title">{props.valueTwo}</p>
      </div>

      <div className="paramiterMain">
        <p className="parameter-title">{props.labelThree}</p>
        <p className="parameter-value parameter-title">{props.valueThree}</p>
      </div>

      <div className="paramiterMain">
        <p className="parameter-title">{props.labelFour}</p>
        <p className="parameter-value parameter-title">{props.valueFour}</p>
      </div> */}
    </div>
  );
};

export default AboutModel;
