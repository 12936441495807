import React, { Fragment } from "react";
import SocialMedia from "../SocialMedia";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { LOGO2 } from "../images";

import "./default.css";
import moment from "moment";

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="container">
        <div className="d-flex justify-content-center gap-2  mt-tab">
          <SocialMedia>
            <FaFacebookF color="white" />
          </SocialMedia>
          <SocialMedia>
            <FaTwitter color="white" />
          </SocialMedia>
          <SocialMedia>
            <FaInstagram color="white" />
          </SocialMedia>
        </div>

        <div className="text-center ">
          <img src={LOGO2} className="footerLogo" />
        </div>

        <div>
          <p className="copyRigth-text color-gray">
            © Copyright {moment().format('YYYY')} All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
