import React from "react";
import { LineWave } from "react-loader-spinner";

const Loader = () => {
  return (
    <div>
      <div className="pt-3 text-center">
        <div className="d-flex align-items-center justify-content-center set_loader">
          <LineWave
            visible={true}
            height="100"
            width="100"
            color="White"
            ariaLabel="line-wave-loading"
            wrapperStyle={{}}
            wrapperClass=""
            firstLineColor=""
            middleLineColor=""
            lastLineColor=""
          />
        </div>
      </div>
    </div>
  );
};

export default Loader;
