import React, { useEffect, useState } from "react";
import "./modelsPage.css";
import Model from "../../componet/Model";
import { FaChevronRight } from "react-icons/fa";
import ModelHero from "../../componet/ModelHero";
import NavbarItems from "../../componet/NavbarItems";
import Default from "../../componet/theme/Default";
import { useDispatch, useSelector } from "react-redux";
import { getModel } from "../../store/Models/Models.action";
import Buttons from "../../componet/Buttons";
import Loader from "../../componet/theme/Loader";
import { useLocation, useParams } from "react-router-dom";

const ModelsPage = () => {
  
  const _models = useSelector((state) => state.modelsReducer.models);
  const dispatch = useDispatch();

  const [showLoadMore, setShowLoadMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const params = useParams();
  const category = params.category;

  // console.log("category", category);

  const loadModels = async (requestData) => {
    setIsLoading(true);

    try {
      dispatch(getModel(requestData));
    } catch (error) {
      console.error("Error loading models:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoadMore = () => {
    // setIsLoading(true);
    const data = {
      limit: 8,
      skip: _models.length,
    };
    dispatch(getModel(data, true));
    // setIsLoading(false);
  };

  useEffect(() => {
    if (category === "women") {
      loadModels({
        filter: {
          gender: "Female",
        },
      });
    } else if (category === "man") {
      loadModels({
        filter: {
          gender: "Male",
        },
      });
    } else if (category === "couples") {
      loadModels({
        filter: {
          gender: "Male",
        },
      });
    } else if (category === "fetish") {
      loadModels({
        filter: {
          gender: "Male",
        },
      });
    } else if (category === "trans") {
      loadModels({
        filter: {
          gender: "Male",
        },
      });
    } else {
      loadModels({
        limit: 8,
      });
    }
  }, [category]);

  if (isLoading) {
    return (
      <div className="set-loader">
        <Loader />
      </div>
    );
  }

  return (
    <Default>
      <ModelHero title="OUR MODELS" />

      <NavbarItems className="mt-5 " />

      {_models.length !== 0 ? (
        <div className="row me-0 ms-2 pt-5 modelPt">
          {_models.map((model) => (
            <div className="col-md-3 mt-3 modelWidth">
              <Model model={model} />
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center mt-5 blankArrayText">
          No result found !!!
        </div>
      )}

      {showLoadMore && (
        <div onClick={handleLoadMore} className="text-center mt-5">
          <Buttons btnName="Load More" />
        </div>
      )}
    </Default>
  );
};

export default ModelsPage;
