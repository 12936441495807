import React, { Fragment, useEffect, useState } from "react";
import InputForm from "./InputForm";
import Buttons from "./Buttons";
import { FaLocationDot } from "react-icons/fa6";
import { GiPathDistance } from "react-icons/gi";
import { FaTransgender } from "react-icons/fa";
import { FaUsers } from "react-icons/fa6";
import FormControl from "./FormControl";
import PostRequest from "../services/PostRequest";
import { useDispatch } from "react-redux";
import {
  getAnnouncementsFromAPI,
  getModel,
} from "../store/Models/Models.action";
import { IoIosClose } from "react-icons/io";

import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";

import { Country, State, City } from "country-state-city";

const NavbarItems = (props) => {


  const {skip = 0} = props;

  const { category = "", subCategory = '' } = useParams();
  const [navbarItem, setNavbarItem] = useState(false);
  const [cities, setCities] = useState([]);
  const [country, setCountry] = useState('BE');
  const [filter, setFilter] = useState({});

  const dispatch = useDispatch();

  const filterData = async (event) => {
    
    event.preventDefault();
    const formData = new FormData(event.target);

    var object = {};
    formData.forEach(function (value, key) {
      object[key] = value;
    });
    // console.log("FilterData", object);

    setFilter({
      isVerified: object.isVerified === "on" ? true : false,
      country: object.country,
      city: object.city,
      subCategory: object.subCategory,
      age: object.age,
    })

    // dispatch(getModel(data));
    // event.target.reset();
  };

  const clearData = () => {
    setFilter({})
    setNavbarItem(false)
  };

  const isActiveURL = (cat) => {
    return cat == category;
  };

  const loadAnnouncements = async (data, isLoadMore = false) => {
    dispatch(getAnnouncementsFromAPI(data,isLoadMore));
  };

  const handleOnCountryChange = (e) => {
    setCountry(e.target.value)
    
  };


  useEffect(() => {
    setCities(City.getCitiesOfCountry(country));
  },[country])

  useEffect(() => {
    const data = {
      category: category,
      subCategory: subCategory,
      skip : props.skip ? props.skip : 0,
      limit: 48,
      filter : filter
    };
    loadAnnouncements(data, skip == 0 ? false : true);
  }, [category,filter,subCategory, skip]);

  return (
    <Fragment>
      <div className="container">
        <div
          className={`nevBar  d-flex justify-content-around ${props.className}`}
        >
          <NavLink to={"/home"}>
            <div className={isActiveURL("") ? "active-mode" : ""}>
              <p className="nevBarTxt headerIcons pt-3">All</p>
            </div>
          </NavLink>

          <NavLink to={"/home/Private"}>
            <div className={isActiveURL("Private") ? "active-mode" : ""}>
              <p className="nevBarTxt headerIcons pt-3">Private</p>
            </div>
          </NavLink>

          <NavLink to={"/home/Escort"}>
            <div className={isActiveURL("Escort") ? "active-mode" : ""}>
              <p className="nevBarTxt headerIcons pt-3">Escort</p>
            </div>
          </NavLink>

          <NavLink to={"/home/Others"}>
            <div className={isActiveURL("Others") ? "active-mode" : ""}>
              <p className="nevBarTxt headerIcons pt-3">Others</p>
            </div>
          </NavLink>

          <p
            className="nevBarTxt headerIcons pt-3 cursorEffect"
            onClick={() => {
              setNavbarItem(!navbarItem);
            }}
          >
            filter
          </p>
        </div>
      </div>

      {navbarItem && (
        <div>
          <form onSubmit={filterData}>
            <div className="container navbarItems ">
              <div className="row">
                <div className="col-md-6">
                  <select
                    onChange={handleOnCountryChange}
                    name="country"
                    id="country"
                    className="form-input input-title inputWidth form-control mb-4"
                    defaultValue={'BE'}
                    style={{ borderRadius: "none", color: "white" }}
                  >
                    <option value="">Select Country</option>
                    {Country.getAllCountries().map((country, index) => (
                      <option
                        key={index}
                        value={country.isoCode}
                        style={{ color: "black" }}
                      >
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6">
                  <select
                    name="city"
                    id="city"
                    className="form-input input-title inputWidth form-control mb-4"
                    style={{ borderRadius: "none", color: "white" }}
                  >
                    <option value="">Select City</option>
                    {cities.map((city, index) => (
                      <option
                        key={index}
                        value={city.name}
                        style={{ color: "black" }}
                      >
                        {city.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  {/* <p className="input-title">gender</p> */}
                  <select
                    name="subCategory"
                    id="subCategory"
                    className="form-input input-title inputWidth"
                    style={{ borderRadius: "none", color: "white" }}
                  >
                    <option value="">-Select Category-</option>
                    <option value="Male" style={{ color: "black" }}>
                      Male
                    </option>
                    <option value="Girl" style={{ color: "black" }}>
                      Girl
                    </option>
                    <option value="Trans" style={{ color: "black" }}>
                    Trans
                    </option>
                    <option value="Couple" style={{ color: "black" }}>
                      Couple
                    </option>
                    <option value="Job" style={{ color: "black" }}>
                      Job
                    </option>
                    <option value="Massage" style={{ color: "black" }}>
                      Massage
                    </option>
                    <option value="Address" style={{ color: "black" }}>
                      Address
                    </option>
                  </select>
                </div>
                <div className="col-md-6">
                  <input
                    name="age"
                    placeHolder="Age"
                    inputType="number"
                    className="inputWidth form-input filterInput"
                  />
                </div>
              </div>

              <div className="d-flex justify-content-between align-items-center mt-4 ">
                <div className="d-flex align-items-center">
                  <input name="isVerified" type="checkbox" />
                  <span className="input-title ms-2 sm-font-set">
                    only verified
                  </span>
                </div>
                <div className="d-flex align-items-center gap-3">
                  {/* <button className="navBtn" type="submit">
                    Filter
                  </button> */}
                  <div onClick={clearData} className="labelClose">
                    <label>CLEAR FILTER</label>
                    <IoIosClose size={"25px"} />
                  </div>

                  {/* <Buttons btnName="Filter" type="submit" /> */}
                  <button
                    className="btn-schedule in-btn-txt btn-schedule-filter"
                    type="submit"
                  >
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </Fragment>
  );
};

export default NavbarItems;
