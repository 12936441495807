import React from "react";
import HeroSection from "../../componet/hero/HeroSection";
import Models from "../../componet/Models";
import BecomeModel from "../../componet/become-model/BcomeModel";
import AllModels from "../../componet/models/AllModels";
import ItemContent from "../../componet/ItemContent";
import ContactUs from "../../componet/ContactUs";
import Default from "../../componet/theme/Default";
import AboutModel from "../../componet/home-casting/AboutModel";
import Photograph from "../../componet/photographer/Photographer";
import ModelsSlider from "../../componet/modelSlider/ModelsSlider";
import { useSelector } from "react-redux";
import Announcements from "../../componet/announcements/Announcements";

const Home = () => {
  return (
    <Default>
      {/* <HeroSection /> */}

      {/* <div className="home-model-wrapper">
        <Models />
      </div> */}

        <AboutModel />
   

      {/* <BecomeModel /> */}

        <Announcements />
      

      {/* <Photograph /> */}
      {/* <ModelsSlider /> */}
      {/* <ItemContent /> */}
      {/* <ContactUs margintop="-175px">
        <div className="row mt-5">
          <div className="col-md-6 modelDetailsWidth">
            <div className="img-contact">
              <div className="text-center only-pt-space">
                <h4 className="media-heading">Become a Model</h4>

                <p className="know-more-title">Know more</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 set-mt modelDetailsWidth mt-tab">
            <div className="img-contact">
              <div className="text-center only-pt-space">
                <h4 className="media-heading">Become a Model</h4>

                <p className="know-more-title">Know more</p>
              </div>
            </div>
          </div>
        </div>
      </ContactUs> */}
    </Default>
  );
};

export default Home;
