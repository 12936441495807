import React, { Fragment, useEffect, useState } from 'react'
import { FRANCE_FLAG, INDIAN_FLAG, PORTUGAL_FLAG, SPAIN_FLAG, US_FLAG } from './images'
import { ASSETS_URL } from '../config/api-urls'

const TranslateDropdown = () => {
  const [selectLanguage, setSelectLagnguage] = useState(US_FLAG);



  const selectedOption = (img, lang) => {

    setSelectLagnguage(img)
    console.log('language', lang)
    var selectElement = document.querySelector('.goog-te-combo');

    // Check if the element is found
    if (selectElement) {
      // Set the new value for the <select> element
      selectElement.value = lang;

      // Create a new 'change' event
      var event = new Event('change', {
        bubbles: true,
        cancelable: true
      });

      // Dispatch the 'change' event on the <select> element
      selectElement.dispatchEvent(event);
    } else {
      console.log('Element with class "goog-te-combo" not found.');
    }

    // <select className='form-control'>
    //     <option><img src={US_FLAG} alt='' /> English</option>
    //     <option><img src={US_FLAG} alt='' /> English</option>
    //     <option><img src={US_FLAG} alt='' /> English</option>
    //     <option><img src={US_FLAG} alt='' /> English</option>
    // </select>

  };


  const googleTranslateElementInit = () => {
    
    try {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: "en",
          autoDisplay: false
        },
        "google_translate_element"
      );
    } catch (error) {

      console.log('error',error)
      
    }

  };

  useEffect(() => {
    
    try {
      var addScript = document.createElement("script");
      addScript.setAttribute(
        "src",
        "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
      );
      document.body.appendChild(addScript);
      window.googleTranslateElementInit = googleTranslateElementInit;
    } catch (error) {
      console.log('error',error)
    }

  }, []);


  const language = [
    {
      languageName: 'English',
      flag: US_FLAG,
      code: 'en'

    },
    {
      languageName: 'French',
      flag: FRANCE_FLAG,
      code: 'fr'

    },
    {
      languageName: 'Spanish',
      flag: SPAIN_FLAG,
      code: 'es'

    },
    {
      languageName: 'Portuguese',
      flag: PORTUGAL_FLAG,
      code: 'pt'

    },
  ]





  return (


    <Fragment>
      <div className="dropdown">
        <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          {selectLanguage ? <img style={{ width: '30px', height: '30px', borderRadius: '100%', backgroundColor: 'transparent' }} src={selectLanguage} alt='' /> : 'Select Language'}
        </button>
        <ul className="dropdown-menu">
          {
            language.map((lang, index) => (
              <li key={index}><a className="dropdown-item" onClick={() => { selectedOption(lang.flag, lang.code) }}><img src={lang.flag} alt='' /> {lang.languageName}</a></li>
            ))
          }
        </ul>
      </div>
      <div style={{ display: 'none' }} id="google_translate_element"  ></div>
    </Fragment>

  )
}

export default TranslateDropdown
