import React, { Fragment } from "react";
import './about.css'
import ModelHero from "../../componet/ModelHero";
import Default from "../../componet/theme/Default";
import Photograph from "../../componet/photographer/Photographer";

const About = () => {
  return (
    <Default>


      <ModelHero title="About" />

      {/* <AboutModel /> */}
      <div className="mt-5 pt-5">
        <div className="mt-5 pt-4">

          <Photograph />

        </div>
      </div>

    </Default>
  );
};

export default About;
