import React, { Fragment } from "react";

const InputForm = (props) => {
  return (
    <Fragment>
      <div className="mt-3">
        <p className="input-title">{props.titel}</p>

        <div className="d-flex">
          {/* <span className="input-group-text form-icons "
                        id="basic-addon1">{props.formIcon}
                    </span> */}
          {props.children}
          <input
            {...props}
            type={props.inputType}
            name={props.name}
            className={`form-input w-100 ${props.className}`}
            placeholder={props.placeHolder}
            onChange={props.onChange}
            onBlur={props.onBlur}
            value={props.value}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default InputForm;
