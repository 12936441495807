import React from "react";
import { CASTING } from "../images";

const AddressImg = (props) => {
  return (
    <div>
      <img src={props.path} className="about-user" />
    </div>
  );
};

export default AddressImg;
