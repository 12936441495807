export const HEADERLOGO = require("../assest/images/logo-black.png")
export const HEADERARROW = require("../assest/images/Header → Button.png")
export const UESR = require("../assest/images/Header → Link.png")

export const MODELS1 = require("../assest/images/model_img_9-500x625.jpg.png")
export const SIGNATURE = require("../assest/images/signature.png")

export const PHOTOGRAPHER = require("../assest/images/avatar_1.png")

export const TEAMIMAGE1 = require("../assest/images/team_slide_1.png")

export const POST = require("../assest/images/post.jpeg")

export const LOGO2 = require("../assest/images/logo-black.png")

export const MAP = require("../assest/images/map.jpg")

export const MENUBG = require("../assest/images/bg-menu.jpg")

export const BUTTERFLY = require("../assest/images/slide_icon.png")
export const LETESTPOST = require("../assest/images/letestPost.png")

export const LETESTPOST1 = require("../assest/images/letestPost.png")

export const WORDPRESSLOGO = require("../assest/images/wordpress-logo.jpg")

export const CASTING = require("../assest/images/team-alt-1-600x600.jpg")

export const MODEL3 = require('../assest/images/model_img_3.jpg')
export const MODEL7 = require('../assest/images/model_img_7.jpg')
export const MODEL12 = require('../assest/images/model_img_12.jpg')
export const MODEL14 = require('../assest/images/model_img_14.jpg')

export const MODEL1 = require('../assest/images/M1.jpg')
export const MODEL2 = require("../assest/images/M2.jpg")
export const MODEL4 = require("../assest/images/M3.jpg")

export const MAP1 = require("../assest/images/map1.png")
export const CHECKMARK = require("../assest/images/checkMark.png")


export const FEMALE_PLACEHOLDER = require("../assest/images/female-placeholder.png")
export const MALE_PLACEHOLDER = require("../assest/images/male-placeholder.jpeg")

export const US_FLAG = require("../assest/images/united-26177_1280.png")
export const INDIAN_FLAG = require('../assest/images/india_flag.png')
export const FRANCE_FLAG = require('../assest/images/france_flag.jpg')
export const SPAIN_FLAG = require('../assest/images/spain_flag.jpg')
export const PORTUGAL_FLAG = require('../assest/images/Portuguese-flag.jpg')
