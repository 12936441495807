import { FEMALE_PLACEHOLDER, MALE_PLACEHOLDER } from "../componet/images";

export const API_URL_LIVE = "https://my-bella.be/";
export const API_URL_LOCAL = "http://localhost:2222/";


export const API_URL =( window.location.href.match('http://localhost') ? API_URL_LOCAL : API_URL_LIVE) + 'api/v1'

export const ASSETS_URL = (path, gender = 'Female') => {
  

  if (path.toString()?.match("http")) return path;

  if(path == '') return gender == 'Male' ? MALE_PLACEHOLDER : FEMALE_PLACEHOLDER;

  return API_URL.split('api/v1').join('') + path;
};


export const isAuth = () => {
  return window.localStorage.getItem('token')
};


export const isMobileDevice = () => {
  // Get the screen width
  var screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

  // Define a threshold for mobile devices (you can adjust this as needed)
  var mobileThreshold = 768;

  // Check if the screen width is less than the threshold
  return screenWidth < mobileThreshold;
}


export const formatPhoneNumberForWhatsApp = (phoneNumber) => {
   // Remove non-numeric characters from the phone number
   const numericPhoneNumber = phoneNumber.replace(/\D/g, '');

   // Check if the phone number starts with a valid country code (you may customize this based on your needs)
   const isValidCountryCode = /^(\+|00)[1-9]\d{0,3}/.test(numericPhoneNumber);
 
   if (isValidCountryCode) {
     // Remove leading zeros from the local part of the phone number
     const formattedPhoneNumber = numericPhoneNumber.replace(/^(0+)/, '');
  
     return formattedPhoneNumber;
    //  return formattedPhoneNumber;
   } else {
     return phoneNumber;
   }
}
